import React, { useEffect, useState } from 'react';
import Layout from 'Components/Layout';
import { Helmet } from 'react-helmet';
import CommonClasses from 'Styles/Common.module.css';
import TutorClasses from '../../Categories/addCategory/index.module.css';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Services } from 'Services';
import ReactLoading from 'react-loading';
import { BsCloudUpload } from 'react-icons/bs';
import GiftClasses from '../Gifts.module.css';
import S3 from 'react-s3';
import { Success, Error, warningSwal } from 'Util/Toast';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

window.Buffer = window.Buffer || require('buffer').Buffer;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const AddGifts = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [images, setImages] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);
    const [categories, setCategories] = useState([]);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const {
        register,
        handleSubmit,
        setValue,
        getValues,
        control,
        formState: { errors },
    } = useForm();

    const config = {
        bucketName: process.env.REACT_APP_BUCKETNAME,
        dirName: 'Gifts',
        region: 'ap-south-1',
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    };

    const filehandleChange = async (event) => {
        const files = Array.from(event.target.files);
        const newImages = [];
        const newImagePreviews = [];

        for (const file of files) {
            if (file.type === 'image/png' || file.type === 'image/jpeg' || file.type === 'image/webp') {
                try {
                    const timestamp = Date.now();
                    const newfile = new File([file], `${timestamp}.${String(file.type).split('/')[1]}`);

                    setLoading(true);
                    const res = await S3.uploadFile(newfile, { ...config, dirName: 'Gifts' });

                    const endpoint = res.location.split('/').pop();
                    newImages.push(endpoint);
                    newImagePreviews.push(URL.createObjectURL(file));
                } catch (error) {
                    console.error('Error uploading image:', error);
                    setLoading(false);
                    Error('Error uploading image');
                }
            } else {
                alert(`${file.name} is neither PNG nor jpeg`);
            }
        }

        setImages([...images, ...newImages]);
        setImagePreviews([...imagePreviews, ...newImagePreviews]);
        setValue('GiftImages', [...images, ...newImages]);
        setLoading(false);
    };

    const onSubmit = async (data) => {
        setLoading(true);
        try {
            const formData = {
                ...data,
                GiftImages: images,
                ProductID: Number(data.ProductID),
                VendorID: Number(data.VendorID),
            };

            const response = await Services.addGifts("POST", JSON.stringify(formData), token);
            if (response.Status === 1) {
                setLoading(false);
                navigate('/gifts');
                Success(response.Message);
            } else {
                setLoading(false);
            }
        } catch (error) {
            console.error('Error adding gift:', error);
            setLoading(false);
        }
    };

    const fileuploadDesign = (
        <div className={TutorClasses["add-item"]} onClick={() => document.getElementById("image").click()}>
            <div className={TutorClasses['upload-image']}>
                {imagePreviews.map((preview, index) => (
                    <img key={index} src={preview} alt="Selected" className={TutorClasses['uploaded-coupon-image']} />
                ))}
                {!imagePreviews.length && (
                    <div className={TutorClasses['add-item']}>
                        <BsCloudUpload className={TutorClasses['upload-icon']} />
                        <h5>Drag Your Files here</h5>
                        <p>(Only *.jpeg, *.png will be accepted)</p>
                    </div>
                )}
                <input type="file" id="image" onChange={filehandleChange} multiple style={{ display: 'none' }} />
            </div>
        </div>
    );

    const fetchCategories = () => {
        setLoading(true);
        Services.getCategories("POST", null, token)
            .then((response) => {
                setLoading(false);
                if (response.Status === 1) {
                    setCategories(response.Categories);
                } else if (response.Status === 0) {
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert("Something went wrong. Please try again.");
                console.error(err);
            });
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    const validateOfferPrice = (offerPrice) => {
        const price = getValues('Price');
        return parseFloat(offerPrice) <= parseFloat(price) || 'Offer price should not be greater than the price';
    };

    return (
        <Layout Active={'Gifts'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Create Gift</title>
            </Helmet>
            <div className={CommonClasses['Container']}>
                <div className={CommonClasses["control-btns"]}>
                    <button className={CommonClasses["button"]} onClick={() => navigate(-1)}>Back</button>
                </div>
                <div className={TutorClasses['wrapper']} data-aos="zoom-in-down">
                    <h2 className={TutorClasses['main-header']}>Create Gift</h2>
                    <form className={TutorClasses["form"]} onSubmit={handleSubmit(onSubmit)}>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="GiftName">Gift Name</label>
                            <input type='text' {...register('GiftName', { required: true })} />
                            {errors.GiftName && <p className={GiftClasses["error-message"]}>Gift Name is required</p>}
                        </div>

                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="ProductID">ProductID</label>
                            <input type='number' step="0.01" {...register('ProductID', { required: true })} />
                            {errors.ProductID && <p className={GiftClasses["error-message"]}>ProductID is required</p>}
                        </div>

                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="VendorID">VendorID</label>
                            <input type='number' step="0.01" {...register('VendorID', { required: true })} />
                            {errors.VendorID && <p className={GiftClasses["error-message"]}>VendorID is required</p>}
                        </div>

                        <div className={TutorClasses['file-upload-wrapper']}>
                            <header>
                                <h3>
                                    Upload Gift Images<span className="important">*</span>
                                </h3>
                            </header>
                            <div className={TutorClasses["upload-image"]} required>
                                {fileuploadDesign}
                            </div>
                        </div>

                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="Price">Price</label>
                            <input type='number' step="0.01" {...register('Price', { required: true })} />
                            {errors.Price && <p className={GiftClasses["error-message"]}>Price is required</p>}
                        </div>

                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="OfferPrice">Offer Price</label>
                            <input type='number' step="0.01" {...register('OfferPrice', {
                                required: true,
                                validate: validateOfferPrice
                            })} />
                            {errors.OfferPrice && <p className={GiftClasses["error-message"]}>{errors.OfferPrice.message}</p>}
                        </div>

                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="GiftDescription">Gift Description</label>
                            <textarea {...register('GiftDescription', { required: true })} />
                            {errors.GiftDescription && <p className={GiftClasses["error-message"]}>Gift Description is required</p>}
                        </div>

                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="Material">Material</label>
                            <input type='text' {...register('Material', { required: true })} />
                            {errors.Material && <p className={GiftClasses["error-message"]}>Material is required</p>}
                        </div>

                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="Capacity">Capacity</label>
                            <input type='text' {...register('Capacity', { required: true })} />
                            {errors.Capacity && <p className={GiftClasses["error-message"]}>Capacity is required</p>}
                        </div>

                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="ProductDimension">Product Dimension</label>
                            <input type='text' {...register('ProductDimension', { required: true })} />
                            {errors.ProductDimension && <p className={GiftClasses["error-message"]}>Product Dimension is required</p>}
                        </div>

                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="TopDiameter">Top Diameter</label>
                            <input type='text' {...register('TopDiameter', { required: true })} />
                            {errors.TopDiameter && <p className={GiftClasses["error-message"]}>Top Diameter is required</p>}
                        </div>

                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="PackageInclude">Package Include</label>
                            <input type='text' {...register('PackageInclude', { required: true })} />
                            {errors.PackageInclude && <p className={GiftClasses["error-message"]}>Package Include is required</p>}
                        </div>

                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="QuantityAvailable">Quantity Available</label>
                            <input type='number' {...register('QuantityAvailable', { required: true })} />
                            {errors.QuantityAvailable && <p className={GiftClasses["error-message"]}>Quantity Available is required</p>}
                        </div>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="CategoryID">Category Name</label>
                            <Controller
                                name="CategoryIDs"
                                control={control}
                                defaultValue={[]}
                                render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                        multiple
                                        options={categories}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.CategoryName}
                                        onChange={(event, newValue) => {
                                            const selectedOptions = newValue.map(option => option.CategoryID);
                                            onChange(selectedOptions);
                                        }}
                                        value={categories.filter(cat => value.includes(cat.CategoryID))}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.CategoryName}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField {...params} label="Categories" placeholder="Select Categories" sx={{ width: '400px' }} />
                                        )}
                                    />
                                )}
                            />
                            {errors.CategoryIDs && <p className={GiftClasses["error-message"]}>Category is required</p>}
                        </div>

                        <button disabled={loading}>
                            {loading ? (
                                <ReactLoading color="green" type="spokes" height={30} width={30} />
                            ) : (
                                'Submit'
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </Layout>
    );
};

export default AddGifts;
