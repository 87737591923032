import React, { useState, useEffect } from 'react';
import Layout from 'Components/Layout';
import { Helmet } from 'react-helmet';
import CommonClasses from 'Styles/Common.module.css';
import TutorClasses from '../../Categories/addCategory/index.module.css';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { Services } from 'Services';
import ReactLoading from 'react-loading';
import { BsCloudUpload } from 'react-icons/bs';
import S3 from 'react-s3';
import { Success, Error } from 'Util/Toast';
window.Buffer = window.Buffer || require('buffer').Buffer;

const EditCategory = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { category } = state || {};
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState(category?.CategoryImage || '');
    const [imagePreview, setImagePreview] = useState(category?.CategoryImage || '');

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        if (category) {
            setValue('CategoryName', category.CategoryName);
            setValue('CategoryID', category.CategoryID);  // Ensure CategoryID is set
            setImage(category.CategoryImage);
        }
    }, [category, setValue]);

    const config = {
        bucketName: process.env.REACT_APP_BUCKETNAME,
        dirName: 'Categories',
        region: 'ap-south-1',
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    };

    const filehandleChange = async (event) => {
        const files = event.target.files[0];
        if (files && (files.type === 'image/png' || files.type === 'image/jpeg' || files.type === 'image/webp')) {
            try {
                const timestamp = Date.now();
                const newfile = new File([files], `${timestamp}.${String(files.type).split('/')[1]}`);

                setLoading(true);
                const res = await S3.uploadFile(newfile, { ...config, dirName: 'Categories' });
                setImage(res.location); // Update image state with the image URL
                setImagePreview(URL.createObjectURL(files)); // Set image preview
                setValue('CategoryImage', `${timestamp}.${String(files.type).split('/')[1]}`);
                setLoading(false);
            } catch (error) {
                console.error('Error uploading image:', error);
                setLoading(false);
                Error('Error uploading image');
            }
        } else {
            alert(`${files.name} is neither PNG nor JPEG`);
        }
    };

    const onSubmit = async (body) => {
        setLoading(true);
        try {
            // Extract filename from image
            const filename = image.split('/').pop();
            // Add filename to data
            body.CategoryImage = filename;
            body.CategoryID = category.CategoryID;  // Ensure CategoryID is in the body

            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.updateCategory(body.CategoryID, "PUT", JSON.stringify(body), token);
            if (response.Status === 1) {
                // Banner updated successfully
                setLoading(false);
                // Redirect to a different page or perform any other action
                navigate('/categories');
                Success(response.Message);
            } else {
                // Handle error
                setLoading(false);
                Error(response.Message);
            }
        } catch (error) {
            console.error('Error updating banner:', error);
            setLoading(false);
            Error('Error updating banner');
        }
    };

    const fileuploadDesign = (
        <div className={TutorClasses["add-item"]} onClick={() => document.getElementById("image").click()}>
            <div className={TutorClasses['upload-image']}>
                {imagePreview && <img src={imagePreview} alt="Selected" className={TutorClasses['uploaded-coupon-image']} />}
                {!imagePreview && (
                    <div className={TutorClasses['add-item']} onClick={() => document.getElementById("image").click()}>
                        <BsCloudUpload className={TutorClasses['upload-icon']} />
                        <h5>Drag Your Files here</h5>
                        <p>(Only *.jpeg, *.png will be accepted)</p>
                    </div>
                )}
                <input type="file" id="image" onChange={filehandleChange} style={{ display: 'none' }} />
            </div>
        </div>
    );

    return (
        <Layout Active={'Categories'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Update Category</title>
            </Helmet>
            <div className={CommonClasses['Container']}>
                <div className={CommonClasses["control-btns"]}>
                    <button className={CommonClasses["button"]} onClick={() => navigate(-1)}>Back</button>
                </div>
                <div className={TutorClasses['wrapper']} data-aos="zoom-in-down">
                    <h2 className={TutorClasses['main-header']}>Update Category</h2>
                    <form className={TutorClasses["form"]} onSubmit={handleSubmit(onSubmit)}>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="CategoryName">CategoryName</label>
                            <input type='text' {...register('CategoryName')} required />
                        </div>

                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="CategoryStatus">Category Status</label>
                            <select {...register('CategoryStatus')} defaultValue={category?.CategoryStatus || ""} required>
                                <option value="">Select Category Status</option>
                                <option value="1">Active</option>
                                <option value="2">InActive</option>
                            </select>
                        </div>

                        <div className={TutorClasses['file-upload-wrapper']}>
                            <header>
                                <h3>
                                    Upload CategoryImage <span className="important">*</span>
                                </h3>
                            </header>
                            <div className={TutorClasses["upload-image"]} required>
                                {fileuploadDesign}
                            </div>
                        </div>
                        {/* Add more input fields for other properties */}
                        <button disabled={loading}>
                            {loading ? (
                                <ReactLoading color="green" type="spokes" height={30} width={30} />
                            ) : (
                                'Submit'
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </Layout>
    );
};

export default EditCategory;
