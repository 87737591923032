import React, { useState, useEffect } from "react";
import { FaRegEdit, FaPhone } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import { Services } from "Services";
import { warningSwal, successSwal, SuccessSwal } from "Util/Toast";
import SkeletonLoad from "Components/TableLoad/load";
import CommonClasses from "Styles/Common.module.css";
import { Helmet } from "react-helmet";
import Layout from "Components/Layout";
import TutorClasses from '../Categories/addCategory/index.module.css';
import OrderClasses from './OrderDetails.module.css';
import orderimg from '../../Assets/box-img.png';
import userimg from '../../Assets/square-user.jpg';
import { MdModeEditOutline } from "react-icons/md";
import { RxCross2 } from "react-icons/rx";
import { FallingLines, MagnifyingGlass } from "react-loader-spinner";
import Moment from "react-moment";


function OrderDetails() {
    const navigate = useNavigate();
    const { OrderID } = useParams();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const [OrderItems, setOrderItems] = useState([]);
    const token = { Authorization: `Bearer ${localStorage.getItem("token")}` };

    useEffect(() => {
        const fetchOrderDetails = async () => {
            setLoading(true);
            if (OrderID) {
                try {
                    const response = await Services.getOrderDetails(OrderID, token);
                    setTimeout(() => {
                        setLoading(false);
                        if (response.Status === 1) {
                            setData(response.Orders);
                            setOrderItems(response.Orders.OrderItems);
                        } else if (response.Status === 0) {
                            warningSwal("Warning", response.Message);
                        }
                    }, 2000); // 2000 milliseconds = 2 seconds
                } catch (err) {
                    setLoading(false);
                    alert("Something went wrong. Please try again.");
                    console.error(err);
                }
            };
        };

        fetchOrderDetails();
    }, [OrderID]);

    const backButton = () => {
        navigate(-1);
    };

    const handleOrderStatusChange = async (newStatus) => {
        setLoading(true);
        try {
            const body = JSON.stringify({ OrderStatus: newStatus });
            const response = await Services.updateOrder(OrderID, "PUT", body, token);
            setLoading(false);
            if (response.Status === 1) {
                SuccessSwal("Success", "Order status updated successfully");
                setData({ ...data, OrderStatus: newStatus });
            } else {
                warningSwal("Warning", response.Message);
            }
        } catch (err) {
            setLoading(false);
            alert("Something went wrong. Please try again.");
            console.error(err);
        }
    };

    const orderStatusMap = {
        1: "Completed",
        2: "Pending",
        3: "Cancelled"
    };

    return (
        <Layout Active={"Orders"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Order Details</title>
            </Helmet>
            <div className={OrderClasses["Container"]}>
                <div className={OrderClasses["control-btns"]}>
                    <button style={{ marginLeft: '20px' }} onClick={backButton} className={TutorClasses["back"]}>
                        Back
                    </button>
                </div>
                <div className={OrderClasses["main-order-details"]}>
                    <div className={OrderClasses['order-items-main-card']}>
                        <div className={OrderClasses["sub-details-one"]}
                            data-aos-duration="1000">
                            {loading ? (
                                <div style={{ textAlign: 'center' }}>
                                    <FallingLines
                                        height={200}
                                        width={200}
                                        color="#AAB2B6"
                                        ariaLabel="falling-lines-loading"
                                        visible={true}
                                    />
                                </div>
                            ) : data ? (
                                <div className={OrderClasses["cards-container"]}>
                                    <div className={OrderClasses["card"]}>
                                        <div className={OrderClasses['order-info-edit']}>
                                            <h3 className={OrderClasses["card-text-head-two"]}>Order Details</h3>
                                            <button><MdModeEditOutline /></button>
                                        </div>
                                        <div className={OrderClasses["inside-card-one"]}>
                                            <img
                                                style={{ width: "5rem", height: "5rem", objectFit: "fill", borderRadius: '5px' }}
                                                src={data.OrderName !== "NA" ? data.OrderName : orderimg}
                                                onError={(e) => {
                                                    e.currentTarget.onerror = null;
                                                    e.currentTarget.src = orderimg;
                                                }}
                                                alt="banner-img"
                                            />
                                            <div className={OrderClasses['status-one']}>
                                                <p>{data.OrderName}</p>
                                                <select
                                                    className={OrderClasses['status-text']}
                                                    value={data.OrderStatus}
                                                    onChange={(e) => handleOrderStatusChange(e.target.value)}
                                                >
                                                    <option value="1">Completed</option>
                                                    <option value="2">Pending</option>
                                                    <option value="3">Cancelled</option>
                                                </select>
                                            </div>
                                        </div>
                                        <div className={OrderClasses['second-inside-card']}>
                                            <div className={OrderClasses['sub-inside-one']}>
                                                <p className={OrderClasses['inside-price-data']}><span className={OrderClasses['sub-order-text']}>CartTotal:</span>₹{data.CartTotal}</p>
                                                <p className={OrderClasses['inside-price-data']}><span className={OrderClasses['sub-order-text']}>CouponDiscount:</span>₹{data.CouponDiscount}</p>
                                                <p className={OrderClasses['inside-price-data']}><span className={OrderClasses['sub-order-text']}>TotalBeforeDiscount:</span>₹{data.TotalBeforeDiscount}</p>
                                                <p className={OrderClasses['inside-price-data-one']}><span className={OrderClasses['sub-order-text-one']}>Discount:</span> ₹-{data.Discount}</p>
                                                <p className={OrderClasses['inside-price-grand-data']}><span className={OrderClasses['inside-price-grand-data']}>GrandTotal:</span> ₹{data.GrandTotal}</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ) : (
                                <div>
                                    <p>No Order Details Found</p>
                                </div>
                            )}
                        </div>
                        <div className={OrderClasses['inside-order-items-card']}
                            data-aos-duration="1000">
                            <div className={OrderClasses["sub-details-one-new"]}>
                                {loading ? (
                                    <div style={{ textAlign: 'center' }}>
                                        <FallingLines
                                            height={200}
                                            width={200}
                                            color="#AAB2B6"
                                            ariaLabel="falling-lines-loading"
                                            visible={true}
                                        />
                                    </div>
                                ) : OrderItems.length > 0 ? (
                                    <div className={OrderClasses["cards-container"]}>
                                        <h3 className={OrderClasses["card-text-head-four"]}>Order Items</h3>
                                        <div className={OrderClasses["card"]}>
                                            {OrderItems.map((order) => (
                                                <div className={OrderClasses['sub-order-inside-details']}>
                                                    <div className={OrderClasses['order-info-edit']}>
                                                        <h3 className={OrderClasses["card-text-head-two"]}>{order.GiftName}</h3>
                                                        <div className={OrderClasses['order-multi']}>
                                                            <button><RxCross2 /></button>
                                                            <p>{order.Quantity}</p>
                                                        </div>
                                                    </div>
                                                    <div className={OrderClasses["inside-items-one"]}>
                                                        {order.GiftImages.map((image, index) => (
                                                            <img
                                                                key={index}
                                                                style={{ width: "10rem", height: "6.5rem", objectFit: "fill", margin: "0.5rem", borderRadius: '5px' }}
                                                                src={image}
                                                                alt={`gift-${order.GiftID}-${index}`}
                                                                onClick={() => {
                                                                    window.open(order.GiftImages, "_blank");
                                                                }}
                                                            />
                                                        ))}
                                                        <div className={OrderClasses['sub-inside-one']}>
                                                            <p className={OrderClasses['sub-order-text']}><span className={OrderClasses['inside-address-details']}>CreatedAt:{" "}</span> <Moment format="DD-MM-YYYY">{order.CreatedAt}</Moment></p>
                                                            <p className={OrderClasses['sub-order-text']}><span className={OrderClasses['inside-address-details']}>OfferPrice:{" "}</span>₹{order.OfferPrice}</p>
                                                            <p className={OrderClasses['sub-order-text']}><span className={OrderClasses['inside-address-details']}>Price:{" "}</span>₹{order.Price}</p>
                                                            <p className={OrderClasses['sub-order-text']}><span className={OrderClasses['inside-address-details']}>TotalAmount:{" "}</span>₹{order.TotalAmount}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                ) : (
                                    <div>
                                        <p>No Order Details Found</p>
                                    </div>
                                )}
                            </div>
                        </div>
                    </div>
                    <div className={OrderClasses["sub-details-new-two"]}
                        data-aos-duration="1000">
                        {loading ? (
                            <div style={{ textAlign: 'center', marginTop: '150px' }}>
                                <MagnifyingGlass
                                    height={100}
                                    width={100}
                                    color="#AAB2B6"
                                    ariaLabel="falling-lines-loading"
                                    visible={true}
                                />
                            </div>
                        ) : data ? (
                            <div>
                                <div className={OrderClasses["cards-container-new"]}>
                                    <div className={OrderClasses["card"]}>
                                        <div className={OrderClasses['customer-edit']}>
                                            <h3 className={OrderClasses["card-text-head-two"]}>Customer Information</h3>
                                            <button><MdModeEditOutline /></button>
                                        </div>
                                        <div className={OrderClasses['']}>
                                            <div className={OrderClasses['customer-personal-info']}>
                                                <img
                                                    style={{ width: "5rem", height: "5rem", objectFit: "fill", borderRadius: '50px' }}
                                                    src={data.OrderName !== "NA" ? data.OrderName : userimg}
                                                    onError={(e) => {
                                                        e.currentTarget.onerror = null;
                                                        e.currentTarget.src = userimg
                                                    }}
                                                    alt="banner-img"
                                                />
                                                <div className={OrderClasses['status-one-new']}>
                                                    <p className={OrderClasses['cust-id']}>#{data.DeliveryAddress?.UserID || 'NA'}</p>
                                                    <p className={OrderClasses['cust-mob']}><FaPhone className={OrderClasses['cust-phone']} />{data.DeliveryAddress?.MobileNumber || 'NA'}</p>
                                                    <p className={OrderClasses['cust-name']}>{data.DeliveryAddress?.UserName || 'NA'}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className={OrderClasses["cards-container-new"]}>
                                    <div className={OrderClasses["card"]}>
                                        <div className={OrderClasses['sub-inside-two']}>
                                            <div className={OrderClasses['customer-edit']}>
                                                <h3 className={OrderClasses["card-text-head-two"]}>Delivery Address</h3>
                                                <button><MdModeEditOutline /></button>
                                            </div>
                                            <p className={OrderClasses['sub-order-text']}><span className={OrderClasses['inside-address-details']}>BuildingName:{" "}</span>{data.DeliveryAddress?.BuildingName}</p>
                                            <p className={OrderClasses['sub-order-text']}><span className={OrderClasses['inside-address-details']}>AreaName:{" "}</span>{data.DeliveryAddress?.AreaName}</p>
                                            <p className={OrderClasses['sub-order-text']}><span className={OrderClasses['inside-address-details']}>City:{" "}</span> {data.DeliveryAddress?.City}</p>
                                            <p className={OrderClasses['sub-order-text']}><span className={OrderClasses['inside-address-details']}>State:{" "}</span> {data.DeliveryAddress?.State}</p>
                                            <p className={OrderClasses['sub-order-text']}><span className={OrderClasses['inside-address-details']}>Pincode:{" "}</span> {data.DeliveryAddress?.Pincode}</p>
                                        </div>
                                    </div>
                                </div>
                                <div className={OrderClasses["cards-container-new"]}>
                                    <div className={OrderClasses["card"]}>
                                        <div className={OrderClasses['sub-inside-three']}>
                                            <div className={OrderClasses['customer-edit']}>
                                                <h3 className={OrderClasses["card-text-head-two"]}>Payment</h3>
                                                <button><MdModeEditOutline /></button>
                                            </div>
                                            <p className={OrderClasses['sub-order-text']}><span className={OrderClasses['inside-address-details']}>OrderType:{" "}</span>{data.OrderType === 1 ? 'Online' : 'Store'}</p>
                                            <p className={OrderClasses['sub-order-text']}><span className={OrderClasses['inside-address-details']}>PaymentType:{" "}</span>{data.PaymentType === 1 ? 'Cash' : 'Online'}</p>
                                            <p className={OrderClasses['sub-order-text']}><span className={OrderClasses['inside-address-details']}>RazorpayOrderId:{" "}</span> {data.RazorpayOrderId || 'NA'}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) : (
                            <div>
                                <p>No Order Details Found</p>
                            </div>
                        )}
                    </div>
                </div>
            </div>
        </Layout >
    );
}

export default OrderDetails;