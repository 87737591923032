import React, { useState } from 'react';
import Layout from 'Components/Layout';
import { Helmet } from 'react-helmet';
import CommonClasses from 'Styles/Common.module.css';
import TutorClasses from '../../Categories/addCategory/index.module.css';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Services } from 'Services';
import ReactLoading from 'react-loading';
import { BsCloudUpload } from 'react-icons/bs';
import GiftClasses from '../../gifts/Gifts.module.css'
import S3 from 'react-s3';
import { Success, Error } from 'Util/Toast';
window.Buffer = window.Buffer || require('buffer').Buffer;

const AddBanner = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState('');
    const [imagePreview, setImagePreview] = useState('');

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    const config = {
        bucketName: process.env.REACT_APP_BUCKETNAME,
        dirName: 'banners',
        region: 'ap-south-1',
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    };

    const filehandleChange = async (event) => {
        const files = event.target.files[0];
        if (
            files.type === 'image/png' ||
            files.type === 'image/jpeg' ||
            files.type === 'image/webp'
        ) {
            try {
                const timestamp = Date.now();
                const newfile = new File(
                    [files],
                    `${timestamp}.${String(files.type).split('/')[1]}`
                );

                setLoading(true);
                const res = await S3.uploadFile(newfile, {
                    ...config,
                    dirName: 'banners',
                });
                setImage(res.location); // Update image state with the image URL
                setImagePreview(URL.createObjectURL(files)); // Set image preview
                setValue('BannerImage', `${timestamp}.${String(files.type).split('/')[1]}`);
                setLoading(false);
            } catch (error) {
                console.error('Error uploading image:', error);
                setLoading(false);
                Error('Error uploading image');
            }
        } else {
            alert(`${files.name} is neither PNG nor jpeg`);
        }
    };

    const onSubmit = async (body) => {
        if (!image) {
            // Show error message if image is not uploaded
            Error('Please upload an image.');
            return;
        }

        setLoading(true);
        try {
            // Extract filename from image
            const filename = image.split('/').pop();

            // Add filename to data
            body.image = filename;

            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.addBanner("POST", JSON.stringify(body), token);
            if (response.Status === 1) {
                // Banner added successfully
                setLoading(false);
                // Redirect to a different page or perform any other action
                navigate('/banners')
                Success(response.Message)
            } else {
                // Handle error
                setLoading(false);
            }
        } catch (error) {
            console.error('Error adding banner:', error);
            setLoading(false);
        }
    };

    const fileuploadDesign = (
        <div className={TutorClasses["add-item"]} onClick={() => document.getElementById("image").click()}>
            <div className={TutorClasses['upload-image']}>
                {imagePreview && <img src={imagePreview} alt="Selected" className={TutorClasses['uploaded-coupon-image']} />}
                {!imagePreview && (
                    <div className={TutorClasses['add-item']}>
                        <BsCloudUpload className={TutorClasses['upload-icon']} />
                        <h5>Drag Your Files here</h5>
                        <p>(Only *.jpeg, *.png will be accepted)</p>
                    </div>
                )}
                <input type="file" id="image" onChange={filehandleChange} style={{ display: 'none' }} />
            </div>
        </div>
    );

    return (
        <Layout Active={'Banners'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Create Banner</title>
            </Helmet>
            <div className={CommonClasses['Container']}>
                <div className={CommonClasses["control-btns"]}>
                    <button className={CommonClasses["button"]} onClick={() => navigate(-1)}>Back</button>
                </div>
                <div className={TutorClasses['wrapper']} data-aos="zoom-in-down">
                    <h2 className={TutorClasses['main-header']}>Create Banner</h2>
                    <form className={TutorClasses["form"]} onSubmit={handleSubmit(onSubmit)}>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="BannerName">Banner Name</label>
                            <input type='text' {...register('BannerName', { required: true })} />
                            {errors.BannerName && <p className={GiftClasses["error-message"]}>Please enter a banner name.</p>}
                        </div>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="BannerType">Banner Type</label>
                            <select {...register('BannerType', { required: true })}>
                                <option value="">Select Banner Type</option>
                                <option value="1">Event</option>
                                <option value="2">HostEvent</option>
                                <option value="3">Gifts</option>
                            </select>
                            {errors.BannerType && <p className={GiftClasses["error-message"]}>Please select a banner type.</p>}
                        </div>
                        <div className={TutorClasses['file-upload-wrapper']}>
                            <header>
                                <h3>
                                    Upload Image <span className="important">*</span>
                                </h3>
                            </header>
                            <div className={TutorClasses["upload-image"]} required>
                                {fileuploadDesign}
                            </div>
                            {errors.BannerImage && <p className={GiftClasses["error-message"]}>Please upload a banner image.</p>}
                        </div>
                        {/* Add more input fields for other properties */}
                        <button disabled={loading}>
                            {loading ? (
                                <ReactLoading color="green" type="spokes" height={30} width={30} />
                            ) : (
                                'Submit'
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </Layout>
    );
};

export default AddBanner;
