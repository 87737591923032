import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { FaRegEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Services } from "Services";
import { SuccessSwal, warningSwal } from "Util/Toast";
import SkeletonLoad from "Components/TableLoad/load";
import CommonClasses from "Styles/Common.module.css";
import { IoIosWarning } from "react-icons/io";
import { AiFillDelete } from "react-icons/ai";
import { Button } from "@mui/material";
import { TablePagination } from "@mui/material";

function Tutor() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [showModal, setShowModal] = useState(false);
    const [selectedCategory, setSelectedCategory] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const fetchCategories = () => {
        setLoading(true);
        Services.getCategories("POST", null, token)
            .then((response) => {
                setLoading(false);
                if (response.Status === 1) {
                    setData(response.Categories);
                } else if (response.Status === 0) {
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert("Something went wrong. Please try again.");
                console.error(err);
            });
    };

    useEffect(() => {
        fetchCategories();
    }, []);

    const handleDeleteClick = (category) => {
        setSelectedCategory(category);
        setShowModal(true);
    };

    const confirmDelete = () => {
        if (selectedCategory) {
            Services.deleteCategory(selectedCategory.CategoryID, token)
                .then((response) => {
                    if (response.Status === 1) {
                        SuccessSwal("Success", "Category deleted successfully!");
                        fetchCategories(); // Refresh the categories list
                    } else {
                        warningSwal("Warning", response.Message);
                    }
                })
                .catch((err) => {
                    alert("Something went wrong. Please try again.");
                    console.error(err);
                })
                .finally(() => {
                    setShowModal(false);
                    setSelectedCategory(null);
                });
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <>
            {loading ? (
                <SkeletonLoad
                    TableHead={[
                        "Category Image",
                        "Name",
                        "Created",
                        "Status",
                        "Actions",
                    ]}
                />
            ) : data.length > 0 ? (
                <div className={CommonClasses["Table"]}>
                    <table>
                        <thead>
                            <tr>
                                <th>Category Image</th>
                                <th>Name</th>
                                <th>Created </th>
                                <th>Status</th>
                                <th>Actions</th>
                            </tr>
                        </thead>
                        <tbody>
                            {data
                                .slice(
                                    page * rowsPerPage,
                                    page * rowsPerPage + rowsPerPage
                                )
                                .map((item) => (
                                    <tr
                                        key={item.CategoryID}
                                        id={item.CategoryID}
                                        name={item.CategoryName}
                                    >
                                        <td>
                                            {" "}
                                            <img
                                                style={{ width: "6rem" }}
                                                src={item.CategoryImage}
                                                alt="category-img"
                                            />
                                        </td>
                                        <td>{item.CategoryName}</td>

                                        <td>
                                            <Moment format="DD-MM-YYYY">
                                                {item.CreatedAt}
                                            </Moment>{" "}
                                        </td>
                                        <td>
                                            {item.CategoryStatus === 1 ? (
                                                <div
                                                    className={
                                                        CommonClasses[
                                                            "Status-active"
                                                        ]
                                                    }
                                                >
                                                    <span>Active</span>
                                                </div>
                                            ) : (
                                                <div
                                                    className={
                                                        CommonClasses[
                                                            "Status-inactive"
                                                        ]
                                                    }
                                                >
                                                    <span>Inactive</span>
                                                </div>
                                            )}
                                        </td>
                                        <td>
                                            <div
                                                className={
                                                    CommonClasses["Action-btns"]
                                                }
                                            >
                                                <button
                                                    onClick={() =>
                                                        navigate(
                                                            `/updateCategory/${item.CategoryID}`,
                                                            {
                                                                state: {
                                                                    category: item,
                                                                },
                                                            }
                                                        )
                                                    }
                                                >
                                                    <FaRegEdit />
                                                </button>
                                                <button
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        handleDeleteClick(
                                                            item
                                                        );
                                                    }}
                                                >
                                                    <AiFillDelete
                                                        className={
                                                            CommonClasses[
                                                                "del-main-btn"
                                                            ]
                                                        }
                                                    />
                                                </button>
                                            </div>
                                        </td>
                                    </tr>
                                ))}
                        </tbody>
                    </table>
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className={CommonClasses["pagination"]}
                    />
                </div>
            ) : (
                <div>
                    <p>No Categories Found</p>
                </div>
            )}
            {showModal && (
                <div className={CommonClasses["modal"]}>
                    <div
                        className={CommonClasses["modal-content"]}
                        data-aos="zoom-in"
                    >
                        <h1>
                            <IoIosWarning
                                className={CommonClasses["del-warning"]}
                            />
                        </h1>
                        <h3>Confirm Delete</h3>
                        <p>Are you sure you want to delete this Category?</p>
                        <div className={CommonClasses["modal-actions"]}>
                            <Button variant="outlined" onClick={confirmDelete}>
                                OK
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => setShowModal(false)}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Tutor;
