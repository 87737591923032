
import Categories from "pages/Categories/index"
import AddCategory from "../pages/Categories/addCategory"

import Login from "../pages/Login/index"
import Users from "pages/users/Users"
import Banners from "pages/banners/Banners"
import Packages from "pages/packages/Packages"
import Events from "pages/events/Events"
import Orders from "pages/orders/Orders"
import OrderDetails from "pages/orders/OrderDetails"
import Gifts from "pages/gifts/Gifts"
import Coupons from "pages/coupons/Coupons"
import AddBanner from "pages/banners/addBanner/AddBanner"
import EditBanner from "pages/banners/editBanner/EditBanner"
import EditCategory from "pages/Categories/editCategory/EditCategory"
import AddPackage from "pages/packages/addPackage/AddPackage"
import EditPackage from "pages/packages/editPackage/EditPackage"
import AddGifts from "pages/gifts/addGifts/AddGifts"
import EditGifts from "pages/gifts/editGifts/EditGifts"
import BannerDetails from "pages/banners/BannerDetails"
import EventDetails from "pages/events/EventDetails"
import Templates from "pages/templates/Templates"
import AddTemplate from "pages/templates/addTemplate/AddTemplate"
import EditTemplate from "pages/templates/editTemplate/EditTemplate"
import UserDetails from "pages/users/UserDetails"
import CreateCoupon from "pages/coupons/CreateCoupon"
import UpdateCoupon from "pages/coupons/UpdateCoupon"


export const AccessKeys = {
 Categories: 'Categories',
}


export const PageRoutes = [


 { path: '/', element: <Login />, AccessKey: AccessKeys.Login, Type: 'read' },

 { path: '/categories', element: <Categories />, AccessKey: AccessKeys.Categories, Type: 'read' },
 { path: '/categories/:category', element: <AddCategory />, AccessKey: AccessKeys.Categories, Type: 'write' },
 { path: '/updateCategory/:CategoryID', element: <EditCategory />, AccessKey: AccessKeys.Categories, Type: 'read' },
 { path: '/users', element: <Users />, AccessKey: AccessKeys.Categories, Type: 'read' },
 { path: '/userdetails/:UserID', element: <UserDetails />, AccessKey: AccessKeys.Categories, Type: 'read' },
 { path: '/banners', element: <Banners />, AccessKey: AccessKeys.Categories, Type: 'read' },
 { path: '/bannerdetails/:BannerID', element: <BannerDetails />, AccessKey: AccessKeys.Categories, Type: 'read' },
 { path: '/packages', element: <Packages />, AccessKey: AccessKeys.Categories, Type: 'read' },
 { path: '/addpackage', element: <AddPackage />, AccessKey: AccessKeys.Categories, Type: 'read' },
 { path: '/updatepackage/:PackageID', element: <EditPackage />, AccessKey: AccessKeys.Categories, Type: 'read' },
 { path: '/events', element: <Events />, AccessKey: AccessKeys.Categories, Type: 'read' },
 { path: '/eventdetails/:EventID', element: <EventDetails />, AccessKey: AccessKeys.Categories, Type: 'read' },
 { path: '/orders', element: <Orders />, AccessKey: AccessKeys.Categories, Type: 'read' },
 { path: '/order-details/:OrderID', element: <OrderDetails />, AccessKey: AccessKeys.Categories, Type: 'read' },
 { path: '/gifts', element: <Gifts />, AccessKey: AccessKeys.Categories, Type: 'read' },
 { path: '/addgifts', element: <AddGifts />, AccessKey: AccessKeys.Categories, Type: 'read' },
 { path: '/updategift/:GiftID', element: <EditGifts />, AccessKey: AccessKeys.Categories, Type: 'read' },
 { path: '/coupons', element: <Coupons />, AccessKey: AccessKeys.Categories, Type: 'read' },
 { path: '/addcoupon', element: <CreateCoupon />, AccessKey: AccessKeys.Categories, Type: 'read' },
 { path: '/updatecoupon/:CouponCodeID', element: <UpdateCoupon />, AccessKey: AccessKeys.Categories, Type: 'read' },
 { path: '/addbanner', element: <AddBanner />, AccessKey: AccessKeys.Categories, Type: 'read' },
 { path: '/updatebanner/:BannerID', element: <EditBanner />, AccessKey: AccessKeys.Categories, Type: 'read' },
 { path: '/templates', element: <Templates />, AccessKey: AccessKeys.Categories, Type: 'read' },
 { path: '/addtemplate', element: <AddTemplate />, AccessKey: AccessKeys.Categories, Type: 'read' },
 { path: '/updatetemplate/:TemplateID', element: <EditTemplate />, AccessKey: AccessKeys.Categories, Type: 'read' },












]