import React from 'react';
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'; // Import any icon library if you wish

const CustomPrevArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'block',
                background: 'rgba(0, 0, 0, 0.5)',
                borderRadius: '50%',
                padding: '5px',
                left: '10px',
                zIndex: '1',
            }}
            onClick={onClick}
        >
        </div>
    );
};

const CustomNextArrow = (props) => {
    const { className, style, onClick } = props;
    return (
        <div
            className={className}
            style={{
                ...style,
                display: 'block',
                background: 'rgba(0, 0, 0, 0.5)',
                padding: '5px',
                borderRadius: '50%',
                right: '10px',
                zIndex: '1',
            }}
            onClick={onClick}
        >
        </div>
    );
};

export { CustomPrevArrow, CustomNextArrow };
