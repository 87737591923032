import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Services } from "Services";
import SkeletonLoad from "Components/TableLoad/load";
import CommonClasses from "Styles/Common.module.css";
import { Helmet } from "react-helmet";
import Layout from "Components/Layout";
import UserClasses from '../users/Users.module.css';
import TutorClasses from '../Categories/addCategory/index.module.css';
import OrderClasses from '../orders/OrderDetails.module.css';
import Moment from "react-moment";
import BannerClasses from "./Banners.module.css";
import bannerimg from '../../Assets/banner-new-2.jpg';
import EventClasses from '../events/Events.module.css';
import { FallingLines, Grid, Hourglass, RotatingTriangles } from 'react-loader-spinner'; // Import the loader

function BannerDetails() {
    const navigate = useNavigate();
    const { BannerID } = useParams();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    useEffect(() => {
        if (BannerID) {
            Services.getBannerDetails(BannerID, token)
                .then((response) => {
                    if (response.Status === 1) {
                        setData(response.Banners);
                    } else {
                        console.error("Failed to fetch banner details:", response.Message);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching banner details:", error);
                })
                .finally(() => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 2000); // Ensure the loader is shown for at least 2 seconds
                });
        }
    }, [BannerID, token]);

    const backButton = () => {
        navigate(-1);
    };

    const getBannerTypeText = (type) => {
        switch (type) {
            case 1:
                return "Event";
            case 2:
                return "HostEvent";
            case 3:
                return "Gifts";
            default:
                return "Unknown";
        }
    };

    return (
        <Layout Active={"Banners"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Banners</title>
            </Helmet>
            <div className={CommonClasses["Container"]}>
                <div className={OrderClasses["control-btns"]}>
                    <button onClick={backButton} className={TutorClasses["back"]}>
                        Back
                    </button>
                </div>
                <div>
                    {loading ? (
                        <div style={{ textAlign: 'center', margin: '0 auto' }}>
                            <FallingLines
                                height="200"
                                width="200"
                                color="#AAB2B6"
                                // colors={[ '#0077B6', '#FF0D0D']}
                                ariaLabel="rotating-triangles-loading"
                                visible={true}
                            />
                        </div>
                    ) : data ? (
                        <div className={CommonClasses["wrapper"]}>
                            <h2 className={EventClasses.fancyHeading} data-aos="fade-right">Banner Information</h2>
                            <div className={BannerClasses['main-first-card']}>
                                <div className={BannerClasses['main-first-left']}>
                                    <img
                                        style={{ width: "100%", height: "25rem", objectFit: "contain", borderRadius: '20px' }}
                                        src={data.BannerImage !== "NA" ? data.BannerImage : bannerimg}
                                        onError={(e) => {
                                            e.currentTarget.onerror = null;
                                            e.currentTarget.src = bannerimg;
                                        }}
                                        alt="banner-img"
                                    />
                                </div>
                                <div className={BannerClasses['main-first-right']}>
                                    <p className={BannerClasses['bannerid-text']}>#{data.BannerID}</p>
                                    <p>{data.BannerStatus === 1 ? (
                                        <div className={CommonClasses["Status-active"]}>
                                            <span>Active</span>
                                        </div>
                                    ) : (
                                        <div className={CommonClasses["Status-inactive"]}>
                                            <span>Inactive</span>
                                        </div>
                                    )}</p>
                                    <p className={BannerClasses['style-bannername']}>{data.BannerName}</p>
                                    <p className={BannerClasses['style-bannertype']}>{getBannerTypeText(data.BannerType)}</p>
                                    <p className={BannerClasses['style-banner-created']}>Created On:{" "}
                                        <Moment format="YYYY/MM/DD">{data.CreatedAt}</Moment></p>
                                    <div className={BannerClasses['style-bannerdisc-main']}>
                                        <h3>About Banner</h3>
                                        <p>{data.Description || "No description available for this banner."}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>
                            <p>No Banners Found</p>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default BannerDetails;
