import React, { useState, useEffect } from 'react';
import Layout from 'Components/Layout';
import { Helmet } from 'react-helmet';
import CommonClasses from 'Styles/Common.module.css';
import UserClasses from './Users.module.css';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { Services } from 'Services';
import { Success, Error } from 'Util/Toast';
import { format } from 'date-fns';
import { FallingLines, Hearts, Hourglass, MagnifyingGlass, MutatingDots, RotatingLines, RotatingTriangles } from 'react-loader-spinner';
window.Buffer = window.Buffer || require('buffer').Buffer;

const UserDetails = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { user } = state || {};
    const [loading, setLoading] = useState(true);
    const [categories, setCategories] = useState([]);

    const {
        register,
        handleSubmit,
        setValue,
        formState: { errors },
    } = useForm();

    useEffect(() => {
        const fetchUsers = async () => {
            try {
                const token = { Authorization: `token ${localStorage.getItem('token')}` };
                const response = await Services.getUsers("POST", null, token);
                if (response.Status === 1) {
                    setCategories(response.Users);
                } else {
                    Error(response.Message);
                }
            } catch (error) {
                console.error('Error fetching categories:', error);
                Error('Error fetching categories');
            } finally {
                setTimeout(() => {
                    setLoading(false);
                }, 2000); // Ensure the loader is shown for at least 2 seconds
            }
        };

        fetchUsers();

        if (user) {
            setValue('UserName', user.UserName);
            setValue('UserID', user.UserID);
        }
    }, [user, setValue]);

    const formatDate = (dateString) => {
        return format(new Date(dateString), 'MMMM dd, yyyy');
    };

    if (loading) {
        return (
            <Layout Active={'Users'}>
                <div className={CommonClasses['loader-container']}>
                    <Helmet>
                        <meta charSet="utf-8" />
                        <title>User Details</title>
                    </Helmet>
                    <div className={CommonClasses['Container']}>
                        <div className={CommonClasses["control-btns"]}>
                            <button className={CommonClasses["button"]} onClick={() => navigate(-1)}>Back</button>
                        </div>
                        <div style={{ textAlign: 'center', margin: '0 auto' }}>
                            <FallingLines
                                height="200"
                                width="200"
                                color="#AAB2B6"
                                // colors={[ '#0077B6', '#FF0D0D']}
                                ariaLabel="rotating-triangles-loading"
                                visible={true}
                            />
                        </div>
                    </div>
                </div>
            </Layout>
        );
    }

    return (
        <Layout Active={'Users'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>User Details</title>
            </Helmet>
            <div className={CommonClasses['Container']}>
                <div className={CommonClasses["control-btns"]}>
                    <button className={CommonClasses["button"]} onClick={() => navigate(-1)}>Back</button>
                </div>
                <div className={UserClasses["wrapper"]} data-aos="zoom-in">
                    {user ? (
                        <>
                            <div className={UserClasses["right"]}>
                                <div className={UserClasses["info"]}>
                                    <h3>Personal Information</h3>
                                    <div className={UserClasses["info_data"]}>
                                        <div className={UserClasses['inside-one-data']}>
                                            {user.UserID && (
                                                <div className={UserClasses["data"]}>
                                                    <h4>UserID</h4>
                                                    <p>{user.UserID}</p>
                                                </div>
                                            )}
                                            {user.UserName && (
                                                <div className={UserClasses["data"]}>
                                                    <h4>UserName</h4>
                                                    <p>{user.UserName}</p>
                                                </div>
                                            )}
                                            {user.MobileNumber && (
                                                <div className={UserClasses["data"]}>
                                                    <h4>MobileNumber</h4>
                                                    <p>{user.MobileNumber}</p>
                                                </div>
                                            )}
                                            {user.EmailID && (
                                                <div className={UserClasses["data"]}>
                                                    <h4>EmailID</h4>
                                                    <p>{user.EmailID}</p>
                                                </div>
                                            )}
                                            {user.DOB && (
                                                <div className={UserClasses["data"]}>
                                                    <h4>DOB</h4>
                                                    <p>{formatDate(user.DOB)}</p>
                                                </div>
                                            )}
                                            {user.Gender && (
                                                <div className={UserClasses["data"]}>
                                                    <h4>Gender</h4>
                                                    <p>{user.Gender}</p>
                                                </div>
                                            )}
                                        </div>
                                        {user.ProfilePicture && (
                                            <div className={UserClasses['inside-two-data']}>
                                                <img src={user.ProfilePicture} alt="user" width="100" onClick={() => window.open(user.ProfilePicture, "_blank")} />
                                            </div>
                                        )}
                                    </div>
                                    <h3>Address</h3>
                                    <div className={UserClasses["info_data"]}>
                                        {user.House_BuildingName && (
                                            <div className={UserClasses["data"]}>
                                                <h4>House_BuildingName</h4>
                                                <p>{user.House_BuildingName}</p>
                                            </div>
                                        )}
                                        {user.Area && (
                                            <div className={UserClasses["data"]}>
                                                <h4>Area</h4>
                                                <p>{user.Area}</p>
                                            </div>
                                        )}
                                        {user.City && (
                                            <div className={UserClasses["data"]}>
                                                <h4>City</h4>
                                                <p>{user.City}</p>
                                            </div>
                                        )}
                                        {user.State && (
                                            <div className={UserClasses["data"]}>
                                                <h4>State</h4>
                                                <p>{user.State}</p>
                                            </div>
                                        )}
                                        {user.Pincode && (
                                            <div className={UserClasses["data"]}>
                                                <h4>Pincode</h4>
                                                <p>{user.Pincode}</p>
                                            </div>
                                        )}
                                        {user.CountryCode && (
                                            <div className={UserClasses["data"]}>
                                                <h4>CountryCode</h4>
                                                <p>{user.CountryCode}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                                <div className={UserClasses["projects"]}>
                                    <h3>Other Details</h3>
                                    <div className={UserClasses["projects_data"]}>
                                        {user.CreatedAt && (
                                            <div className={UserClasses["data"]}>
                                                <h4>CreatedAt</h4>
                                                <p>{formatDate(user.CreatedAt)}</p>
                                            </div>
                                        )}
                                        {user.UserStatus !== undefined && (
                                            <div className={UserClasses["data"]}>
                                                <h4>UserStatus</h4>
                                                <p>{user.UserStatus === 1 ? 'Active' : 'NA'}</p>
                                            </div>
                                        )}
                                    </div>
                                </div>
                            </div>
                        </>
                    ) : (
                        <p>No user details available.</p>
                    )}
                </div>
            </div>
        </Layout>
    );
};

export default UserDetails;
