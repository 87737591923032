import React, { useState, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Services } from "Services";
import SkeletonLoad from "Components/TableLoad/load";
import CommonClasses from "Styles/Common.module.css";
import { Helmet } from "react-helmet";
import Layout from "Components/Layout";
import UserClasses from '../users/Users.module.css';
import TutorClasses from '../Categories/addCategory/index.module.css';
import OrderClasses from '../orders/OrderDetails.module.css';
import Moment from "react-moment";
import BannerClasses from "../banners/Banners.module.css";
import bannerimg from '../../Assets/banner-new-2.jpg';
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { CustomPrevArrow, CustomNextArrow } from './CustomArrow';
import EventClasses from './Events.module.css';
import { MdOutlineAccessTimeFilled } from "react-icons/md";
import { MdDateRange } from "react-icons/md";
import { TbStatusChange } from "react-icons/tb";
import { FallingLines, RotatingTriangles } from 'react-loader-spinner'; // Import the loader

function EventDetails() {
    const navigate = useNavigate();
    const { EventID } = useParams();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState(null);

    useEffect(() => {
        if (EventID) {
            const token = { Authorization: `token ${localStorage.getItem("token")}` };

            Services.getEventDetails(EventID, token)
                .then((response) => {
                    if (response.Status === 1) {
                        setData(response.Events);
                    } else {
                        console.error("Failed to fetch event details:", response.Message);
                    }
                })
                .catch((error) => {
                    console.error("Error fetching event details:", error);
                })
                .finally(() => {
                    setTimeout(() => {
                        setLoading(false);
                    }, 2000); // Ensure the loader is shown for at least 2 seconds
                });
        }
    }, [EventID]);

    const backButton = () => {
        navigate(-1);
    };

    const getBannerTypeText = (type) => {
        switch (type) {
            case 1:
                return "Event";
            case 2:
                return "HostEvent";
            case 3:
                return "Gifts";
            default:
                return "Unknown";
        }
    };

    const sliderSettings = (imageCount) => ({
        dots: false,
        infinite: imageCount > 1,
        speed: 200,
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: imageCount > 1,
        nextArrow: <CustomNextArrow />,
        prevArrow: <CustomPrevArrow />
    });

    return (
        <Layout Active={"Events"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Events</title>
            </Helmet>
            <div className={CommonClasses["Container"]}>
                <div className={OrderClasses["control-btns"]}>
                    <button onClick={backButton} className={TutorClasses["back"]}>
                        Back
                    </button>
                </div>
                <div>
                    {loading ? (
                        <div style={{ textAlign: 'center', margin: '0 auto' }}>
                            <FallingLines
                                height="200"
                                width="200"
                                color="#AAB2B6"
                                // colors={[ '#0077B6', '#FF0D0D']}
                                ariaLabel="rotating-triangles-loading"
                                visible={true}
                            />
                        </div>
                    ) : data ? (
                        <div className={CommonClasses["wrapper"]}>
                            <h2 className={EventClasses.fancyHeading} data-aos="fade-right">Event Information</h2>
                            <div className={BannerClasses['main-first-card']}>
                                <div className={BannerClasses['main-first-left']} data-aos="zoom-in-right"
                                    data-aos-duration="2000">
                                    <Slider {...sliderSettings(data.EventImages !== "NA" ? data.EventImages.length : 0)}>
                                        {data.EventImages !== "NA" ? data.EventImages.map((image, index) => (
                                            <div key={index} className={BannerClasses.slidercontainer}>
                                                <img
                                                    style={{ width: "100%", height: "25rem", objectFit: "fill" }}
                                                    src={image}
                                                    onError={(e) => {
                                                        e.currentTarget.onerror = null;
                                                        e.currentTarget.src = bannerimg;
                                                    }}
                                                    alt={`banner-img-${index}`}
                                                />
                                            </div>
                                        )) : (
                                            <div className={BannerClasses.slidercontainer}>
                                                <img
                                                    style={{ width: "100%", height: "25rem", objectFit: "fill", borderRadius: '20px' }}
                                                    src={bannerimg}
                                                    alt="default-banner"
                                                />
                                            </div>
                                        )}
                                    </Slider>
                                </div>
                                <div className={BannerClasses['main-first-right']} data-aos="zoom-in-left"
                                    data-aos-duration="2000">
                                    <p className={BannerClasses['bannerid-text']}>#{data.EventID}</p>
                                    <p>{data.EventStatus === 2 ? (
                                        <div className={CommonClasses["Status-active"]}>
                                            <span>Active</span>
                                        </div>
                                    ) : (
                                        <div className={CommonClasses["Status-inactive"]}>
                                            <span>Inactive</span>
                                        </div>
                                    )}</p>
                                    <p className={BannerClasses['style-bannername']}>{data.EventName}</p>
                                    <p className={BannerClasses['style-bannertype']}>{getBannerTypeText(data.BannerType)}</p>
                                    <p className={BannerClasses['style-banner-created']}>Created On:{" "}
                                        <Moment format="YYYY/MM/DD">{data.CreatedAt}</Moment></p>
                                    <div className={BannerClasses['style-bannerdisc-main']}>
                                        <h3>Message To Guests</h3>
                                        <p>{data.EventMessage || "No description available for this banner."}</p>
                                    </div>
                                </div>
                            </div>
                            <div className={EventClasses['main-second-data']}>
                                <div className={EventClasses['main-second-second']}>
                                    <p className={EventClasses['time-style']}><MdDateRange className={EventClasses['icon-time']} /></p>
                                    <p className={EventClasses['time-head']}>{data.EventDate}</p>
                                </div>
                                <div className={EventClasses['main-second-first']}>
                                    <p className={EventClasses['time-style']}><MdOutlineAccessTimeFilled className={EventClasses['icon-time']} /></p>
                                    <h4 className={EventClasses['time-head']}>{data.EventTime}</h4>
                                </div>
                                <div className={EventClasses['main-second-third']}>
                                    <p className={EventClasses['time-style']}><TbStatusChange className={EventClasses['icon-time']} /></p>
                                    <h4 className={EventClasses['time-head']}>{data.EventStatus === 1 ? 'Completed' : "Pending"}</h4>
                                </div>
                            </div>
                            <h2 className={EventClasses.fancyHeading}>Venue Location</h2>
                            <div className={EventClasses['main-third-data']}>
                                <div className={EventClasses['venue-loc']}>
                                    <p>{data.VenueLocation.BuildingName}</p>
                                    <p>{data.VenueLocation.AreaName}</p>
                                    <p>{data.VenueLocation.City}</p>
                                    <p>{data.VenueLocation.State}</p>
                                    <p>{data.VenueLocation.Pincode}</p>
                                </div>
                            </div>
                            <h2 className={EventClasses.fancyHeading}>Delivery Location</h2>
                            <div className={EventClasses['main-third-data']}>
                                <div className={EventClasses['delivery-loc']}>
                                    <p>{data.DeliveryLocation.BuildingName}</p>
                                    <p>{data.DeliveryLocation.AreaName}</p>
                                    <p>{data.DeliveryLocation.City}</p>
                                    <p>{data.DeliveryLocation.State}</p>
                                    <p>{data.DeliveryLocation.Pincode}</p>
                                </div>
                            </div>
                            <h2 className={EventClasses.fancyHeading}>Participants</h2>
                            <div className={EventClasses['main-fourth-data']}>
                                {data.Participants !== "NA" ? (
                                    <div className={CommonClasses["Table"]} style={{marginLeft: '20px'}}>
                                        <table className={EventClasses['participants-table']}>
                                            <thead>
                                                <tr>
                                                    <th>Profile Picture</th>
                                                    <th>Username</th>
                                                    <th>Joined On</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data.Participants.map((participant, index) => (
                                                    <tr key={index}>
                                                        <td>
                                                            <img
                                                                style={{ width: "50px", height: "50px", objectFit: "cover", borderRadius: '50%' }}
                                                                src={participant.ProfilePicture}
                                                                onError={(e) => {
                                                                    e.currentTarget.onerror = null;
                                                                    e.currentTarget.src = bannerimg;
                                                                }}
                                                                alt={`participant-img-${index}`}
                                                                onClick={(e) => {
                                                                    e.stopPropagation();
                                                                    window.open(participant.ProfilePicture, "_blank");
                                                                }}
                                                            />
                                                        </td>
                                                        <td>{participant.UserName}</td>
                                                        <td><Moment format="YYYY/MM/DD">{participant.CreatedAt}</Moment></td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>
                                    </div>
                                ) : (
                                    <div className={BannerClasses.slidercontainer}>
                                        <img
                                            style={{ width: "100%", height: "25rem", objectFit: "fill", borderRadius: '20px' }}
                                            src={bannerimg}
                                            alt="default-banner"
                                        />
                                    </div>
                                )}
                            </div>
                        </div>
                    ) : (
                        <div>
                            <p>No Events Found</p>
                        </div>
                    )}
                </div>
            </div>
        </Layout>
    );
}

export default EventDetails;
