import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { FaRegEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Services } from "Services";
import { warningSwal, SuccessSwal } from "Util/Toast";
import SkeletonLoad from "Components/TableLoad/load";
import CommonClasses from "Styles/Common.module.css";
import { Helmet } from "react-helmet";
import Layout from "Components/Layout";
import { BiPlus } from "react-icons/bi";
import { IoIosWarning } from "react-icons/io";
import { Button } from "@mui/material";
import { AiFillDelete } from "react-icons/ai";
import { TablePagination } from "@mui/material";

function Banners() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [selectedBanner, setSelectedBanner] = useState(null);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const fetchBanners = () => {
        setLoading(true);
        Services.getBanners("POST", null, token)
            .then((response) => {
                setLoading(false);
                if (response.Status === 1) {
                    setData(response.Banners);
                } else if (response.Status === 0) {
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert("Something went wrong. Please try again.");
                console.error(err);
            });
    };

    useEffect(() => {
        fetchBanners();
    }, []);

    const handleDeleteClick = (banner) => {
        setSelectedBanner(banner);
        setShowModal(true);
    };

    const confirmDelete = () => {
        if (selectedBanner) {
            Services.deleteBanner(selectedBanner.BannerID, token)
                .then((response) => {
                    if (response.Status === 1) {
                        SuccessSwal("Success", "Banner deleted successfully!");
                        fetchBanners(); // Refresh the banners list
                    } else {
                        warningSwal("Warning", response.Message);
                    }
                })
                .catch((err) => {
                    alert("Something went wrong. Please try again.");
                    console.error(err);
                })
                .finally(() => {
                    setShowModal(false);
                    setSelectedBanner(null);
                });
        }
    };

    const getBannerTypeLabel = (type) => {
        switch (type) {
            case 1:
                return "Event";
            case 2:
                return "HostEvent";
            case 3:
                return "Gifts";
            default:
                return "Unknown";
        }
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const filteredData = data.filter((item) =>
        item.BannerName.toLowerCase().includes(searchString.toLowerCase())
    );

    const slicedData = filteredData.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );

    return (
        <Layout Active={"Banners"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Banners</title>
            </Helmet>
            <div className={CommonClasses["Container"]}>
                <div className={CommonClasses["control-btns"]}>
                    <button
                        className={CommonClasses["button"]}
                        onClick={() => {
                            navigate("/addBanner");
                        }}
                    >
                        <BiPlus size={20} /> Create New Banner
                    </button>
                </div>
                <div className={CommonClasses["wrapper"]}>
                    {loading ? (
                        <SkeletonLoad
                            TableHead={[
                                "BannerImage",
                                "BannerName",
                                "CreatedAt",
                                "UpdatedAt",
                                "BannerStatus",
                                "BannerType",
                                "Actions",
                            ]}
                        />
                    ) : slicedData.length > 0 ? (
                        <div className={CommonClasses["Table"]}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>BannerImage</th>
                                        <th>BannerName</th>
                                        <th>CreatedAt</th>
                                        <th>UpdatedAt</th>
                                        <th>BannerStatus</th>
                                        <th>BannerType</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {slicedData.map((item) => (
                                        <tr
                                            key={item.BannerID}
                                            id={item.BannerID}
                                            name={item.BannerName}
                                            onClick={() =>
                                                navigate(
                                                    `/bannerdetails/${item.BannerID}`
                                                )
                                            }
                                            style={{ cursor: "pointer" }}
                                        >
                                            <td>
                                                <img
                                                    style={{
                                                        width: "10rem",
                                                        height: "5rem",
                                                        objectFit: "fill",
                                                        borderRadius: "5px",
                                                    }}
                                                    src={item.BannerImage}
                                                    alt="banner-img"
                                                    onClick={(e) => {
                                                        e.stopPropagation();
                                                        window.open(
                                                            item.BannerImage,
                                                            "_blank"
                                                        );
                                                    }}
                                                />
                                            </td>
                                            <td>{item.BannerName}</td>
                                            <td>
                                                <Moment format="DD-MM-YYYY">
                                                    {item.CreatedAt}
                                                </Moment>
                                            </td>
                                            <td>
                                                <Moment format="DD-MM-YYYY">
                                                    {item.UpdatedAt}
                                                </Moment>
                                            </td>
                                            <td>
                                                {item.BannerStatus === 1 ? (
                                                    <div
                                                        className={
                                                            CommonClasses[
                                                                "Status-active"
                                                            ]
                                                        }
                                                    >
                                                        <span>Active</span>
                                                    </div>
                                                ) : (
                                                    <div
                                                        className={
                                                            CommonClasses[
                                                                "Status-inactive"
                                                            ]
                                                        }
                                                    >
                                                        <span>Inactive</span>
                                                    </div>
                                                )}
                                            </td>
                                            <td>
                                                {getBannerTypeLabel(
                                                    item.BannerType
                                                )}
                                            </td>
                                            <td>
                                                <div
                                                    className={
                                                        CommonClasses[
                                                            "Action-btns"
                                                        ]
                                                    }
                                                >
                                                    <button
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            navigate(
                                                                `/updatebanner/${item.BannerID}`,
                                                                {
                                                                    state: {
                                                                        banner: item,
                                                                    },
                                                                }
                                                            );
                                                        }}
                                                    >
                                                        <FaRegEdit />
                                                    </button>
                                                    <button
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleDeleteClick(
                                                                item
                                                            );
                                                        }}
                                                    >
                                                        <AiFillDelete
                                                            className={
                                                                CommonClasses[
                                                                    "del-main-btn"
                                                                ]
                                                            }
                                                        />
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                            <TablePagination
                                rowsPerPageOptions={[5, 10, 25]}
                                component="div"
                                count={filteredData.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                                className={CommonClasses["pagination"]}
                            />
                        </div>
                    ) : (
                        <div>
                            <p>No Banners Found</p>
                        </div>
                    )}
                </div>
            </div>
            {showModal && (
                <div className={CommonClasses["modal"]}>
                    <div
                        className={CommonClasses["modal-content"]}
                        data-aos="zoom-in"
                    >
                        <h1>
                            <IoIosWarning className={CommonClasses["del-warning"]} />
                        </h1>
                        <h3>Confirm Delete</h3>
                        <p>
                            Are you sure you want to delete this banner?
                        </p>
                        <div className={CommonClasses["modal-actions"]}>
                            <Button variant="outlined" onClick={confirmDelete}>
                                OK
                            </Button>
                            <Button
                                variant="outlined"
                                color="secondary"
                                onClick={() => setShowModal(false)}
                            >
                                Cancel
                            </Button>
                        </div>
                    </div>
                </div>
            )}
        </Layout>
    );
}

export default Banners;
