import React, { useState, useEffect } from 'react';
import Layout from 'Components/Layout';
import { Helmet } from 'react-helmet';
import CommonClasses from 'Styles/Common.module.css';
import TutorClasses from '../Categories/addCategory/index.module.css';
import { useForm } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { Services } from 'Services';
import ReactLoading from 'react-loading';
import { Success, Error } from 'Util/Toast';

const UpdateCoupon = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [loading, setLoading] = useState(false);
    const [couponData, setCouponData] = useState(null);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors },
    } = useForm({
        defaultValues: {
            Status: '',
        }
    });

    useEffect(() => {
        if (location.state && location.state.coupon) {
            const { StartDate, EndDate, Status, ...rest } = location.state.coupon;
            const formattedStatus = getStatusText(Status);
            setCouponData({
                ...rest,
                StartDate: formatDate(StartDate),
                EndDate: formatDate(EndDate),
                Status: formattedStatus,
            });
            reset({
                ...rest,
                StartDate: formatDate(StartDate),
                EndDate: formatDate(EndDate),
                Status: formattedStatus,
            });
        }
    }, [location.state, reset]);

    const formatDate = (date) => {
        return date ? new Date(date).toISOString().substr(0, 10) : '';
    };

    const getStatusText = (status) => {
        switch (status) {
            case 1:
                return 'Active';
            case 2:
                return 'Inactive';
            case 0:
                return 'Delete';
            default:
                return 'Active'; // Default to Active if no valid status is provided
        }
    };

    const onSubmit = async (data) => {
        setLoading(true);
        try {
            const formData = {
                CouponCodeID: Number(data.CouponCodeID),
                CouponCode: data.CouponCode,
                Description: data.Description,
                StartDate: data.StartDate,
                EndDate: data.EndDate,
                CouponType: Number(data.CouponType),
                OfferValue: Number(data.OfferValue),
                MaxDiscount: Number(data.MaxDiscount),
                MinOrdervalue: Number(data.MinOrdervalue),
                Status: getStatusValue(data.Status),
            };

            const response = await Services.updateCouponCode(couponData.CouponCodeID, "PUT", JSON.stringify(formData), token);
            if (response.Status === 1) {
                setLoading(false);
                navigate('/coupons');
                Success(response.Message);
            } else {
                Error(response.Message);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error updating coupon:', error);
            Error('An error occurred while updating the coupon.');
            setLoading(false);
        }
    };

    const getStatusValue = (status) => {
        switch (status) {
            case 'Active':
                return 1;
            case 'Inactive':
                return 2;
            case 'Delete':
                return 0;
            default:
                return 1; // Default to Active if no valid status is selected
        }
    };

    if (!couponData) {
        return <div>Loading...</div>;
    }

    return (
        <Layout Active={'Coupons'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Update Coupon</title>
            </Helmet>
            <div className={CommonClasses['Container']}>
                <div className={CommonClasses["control-btns"]}>
                    <button className={CommonClasses["button"]} onClick={() => navigate(-1)}>Back</button>
                </div>
                <div className={TutorClasses['wrapper']} data-aos="zoom-in-down">
                    <h2 className={TutorClasses['main-header']}>Update Coupon</h2>
                    <form className={TutorClasses["form"]} onSubmit={handleSubmit(onSubmit)}>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="CouponCodeID">Coupon Code ID</label>
                            <input type='number' {...register('CouponCodeID', { required: true })} />
                        </div>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="CouponCode">Coupon Code</label>
                            <input type='text' {...register('CouponCode', { required: true })} />
                        </div>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="Description">Description</label>
                            <textarea {...register('Description', { required: true })} />
                        </div>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="StartDate">Start Date</label>
                            <input type='date' {...register('StartDate', { required: true })} />
                        </div>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="EndDate">End Date</label>
                            <input type='date' {...register('EndDate', { required: true })} />
                        </div>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="CouponType">Coupon Type</label>
                            <select {...register('CouponType', { required: true })}>
                                <option value="1">Percentage</option>
                                <option value="2">Amount</option>
                            </select>
                        </div>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="OfferValue">Offer Value</label>
                            <input type='number' step="0.01" {...register('OfferValue', { required: true })} />
                        </div>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="MaxDiscount">Max Discount</label>
                            <input type='number' step="0.01" {...register('MaxDiscount', { required: true })} />
                        </div>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="MinOrdervalue">Min Order Value</label>
                            <input type='number' step="0.01" {...register('MinOrdervalue', { required: true })} />
                        </div>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="Status">Status</label>
                            <select {...register('Status', { required: true })}>
                                <option value="Active">Active</option>
                                <option value="Inactive">Inactive</option>
                                <option value="Delete">Delete</option>
                            </select>
                        </div>
                        <button disabled={loading}>
                            {loading ? (
                                <ReactLoading color="green" type="spokes" height={30} width={30} />
                            ) : (
                                'Submit'
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </Layout>
    );
};

export default UpdateCoupon;
