import React, { useState } from 'react';
import Layout from 'Components/Layout';
import { Helmet } from 'react-helmet';
import CommonClasses from 'Styles/Common.module.css';
import TutorClasses from '../Categories/addCategory/index.module.css';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { Services } from 'Services';
import ReactLoading from 'react-loading';
import GiftClasses from '../gifts/Gifts.module.css'
import { Success, Error } from 'Util/Toast';

const CreateCoupon = () => {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(false);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const {
        register,
        handleSubmit,
        formState: { errors },
    } = useForm();

    const onSubmit = async (data) => {
        setLoading(true);
        try {
            const formData = {
                CouponCodeID: Number(data.CouponCodeID),
                CouponCode: data.CouponCode,
                Description: data.Description,
                StartDate: data.StartDate,
                EndDate: data.EndDate,
                CouponType: Number(data.CouponType),
                OfferValue: Number(data.OfferValue),
                MaxDiscount: Number(data.MaxDiscount),
                MinOrdervalue: Number(data.MinOrdervalue),
            };

            const response = await Services.addCouponCode("POST", JSON.stringify(formData), token);
            if (response.Status === 1) {
                setLoading(false);
                navigate('/coupons');
                Success(response.Message);
            } else {
                Error(response.Message);
                setLoading(false);
            }
        } catch (error) {
            console.error('Error adding coupon:', error);
            Error('An error occurred while adding the coupon.');
            setLoading(false);
        }
    };

    return (
        <Layout Active={'Coupons'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Create Coupon</title>
            </Helmet>
            <div className={CommonClasses['Container']}>
                <div className={CommonClasses["control-btns"]}>
                    <button className={CommonClasses["button"]} onClick={() => navigate(-1)}>Back</button>
                </div>
                <div className={TutorClasses['wrapper']} data-aos="zoom-in-down">
                    <h2 className={TutorClasses['main-header']}>Create Coupon</h2>
                    <form className={TutorClasses["form"]} onSubmit={handleSubmit(onSubmit)}>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="CouponCodeID">Coupon Code ID</label>
                            <input type='number' {...register('CouponCodeID', { required: 'Please enter a coupon code ID.' })} />
                            {errors.CouponCodeID && <p className={GiftClasses["error-message"]}>{errors.CouponCodeID.message}</p>}
                        </div>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="CouponCode">Coupon Code</label>
                            <input type='text' {...register('CouponCode', { required: 'Please enter a coupon code.' })} />
                            {errors.CouponCode && <p className={GiftClasses["error-message"]}>{errors.CouponCode.message}</p>}
                        </div>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="Description">Description</label>
                            <textarea {...register('Description', { required: 'Please enter a description.' })} />
                            {errors.Description && <p className={GiftClasses["error-message"]}>{errors.Description.message}</p>}
                        </div>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="StartDate">Start Date</label>
                            <input type='date' {...register('StartDate', { required: 'Please enter a start date.' })} />
                            {errors.StartDate && <p className={GiftClasses["error-message"]}>{errors.StartDate.message}</p>}
                        </div>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="EndDate">End Date</label>
                            <input type='date' {...register('EndDate', { required: 'Please enter an end date.' })} />
                            {errors.EndDate && <p className={GiftClasses["error-message"]}>{errors.EndDate.message}</p>}
                        </div>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="CouponType">Coupon Type</label>
                            <select {...register('CouponType', { required: 'Please select a coupon type.' })}>
                                <option value={1}>Percentage</option>
                                <option value={2}>Amount</option>
                            </select>
                            {errors.CouponType && <p className={GiftClasses["error-message"]}>{errors.CouponType.message}</p>}
                        </div>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="OfferValue">Offer Value</label>
                            <input type='number' step="0.01" {...register('OfferValue', { required: 'Please enter an offer value.' })} />
                            {errors.OfferValue && <p className={GiftClasses["error-message"]}>{errors.OfferValue.message}</p>}
                        </div>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="MaxDiscount">Max Discount</label>
                            <input type='number' step="0.01" {...register('MaxDiscount', { required: 'Please enter a max discount.' })} />
                            {errors.MaxDiscount && <p className={GiftClasses["error-message"]}>{errors.MaxDiscount.message}</p>}
                        </div>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="MinOrdervalue">Min Order Value</label>
                            <input type='number' step="0.01" {...register('MinOrdervalue', { required: 'Please enter a minimum order value.' })} />
                            {errors.MinOrdervalue && <p className={GiftClasses["error-message"]}>{errors.MinOrdervalue.message}</p>}
                        </div>
                        <button disabled={loading}>
                            {loading ? (
                                <ReactLoading color="green" type="spokes" height={30} width={30} />
                            ) : (
                                'Submit'
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </Layout>
    );
};

export default CreateCoupon;
