import React, { useState, useEffect } from 'react';
import Layout from 'Components/Layout';
import { Helmet } from 'react-helmet';
import CommonClasses from 'Styles/Common.module.css';
import TutorClasses from '../../Categories/addCategory/index.module.css';
import { useForm, Controller } from 'react-hook-form';
import { useNavigate, useLocation } from 'react-router-dom';
import { Services } from 'Services';
import { Success, Error } from 'Util/Toast';
import ReactLoading from 'react-loading';
import { BsCloudUpload } from 'react-icons/bs';
import { IoIosClose } from "react-icons/io";
import S3 from 'react-s3';
import Checkbox from '@mui/material/Checkbox';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

window.Buffer = window.Buffer || require('buffer').Buffer;

const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
const checkedIcon = <CheckBoxIcon fontSize="small" />;

const EditGifts = () => {
    const navigate = useNavigate();
    const { state } = useLocation();
    const { gift } = state || {};
    const [loading, setLoading] = useState(false);
    const [image, setImage] = useState([]);
    const [imagePreviews, setImagePreviews] = useState([]);
    const [categories, setCategories] = useState([]);
    const { register, handleSubmit, setValue, control, formState: { errors } } = useForm();

    useEffect(() => {
        const fetchCategories = async () => {
            try {
                const token = { Authorization: `token ${localStorage.getItem('token')}` };
                const response = await Services.getCategories("POST", null, token);
                if (response.Status === 1) {
                    setCategories(response.Categories);
                } else {
                    Error(response.Message);
                }
            } catch (error) {
                console.error('Error fetching categories:', error);
                Error('Error fetching categories');
            }
        };

        fetchCategories();

        if (gift) {
            setValue('GiftName', gift.GiftName);
            setValue('Price', gift.Price);
            setValue('OfferPrice', gift.OfferPrice);
            setValue('GiftDescription', gift.GiftDescription);
            setValue('Material', gift.Material);
            setValue('Capacity', gift.Capacity);
            setValue('ProductDimension', gift.ProductDimension);
            setValue('TopDiameter', gift.TopDiameter);
            setValue('PackageInclude', gift.PackageInclude);
            setValue('QuantityAvailable', gift.QuantityAvailable);
            setValue('GiftStatus', gift.GiftStatus);
            setValue('ProductID', gift.ProductID);
            setValue('VendorID', gift.VendorID)

            // Handle CategoryIDs
            if (Array.isArray(gift.CategoryIDs)) {
                setValue('CategoryIDs', gift.CategoryIDs);
            } else {
                console.error('CategoryIDs is not an array:', gift.CategoryIDs);
                setValue('CategoryIDs', []);
            }

            const extractedImages = gift.GiftImages.map(url => url.split('/').pop());
            setImage(extractedImages);
            setImagePreviews(gift.GiftImages);
        }
    }, [gift, setValue]);

    const config = {
        bucketName: process.env.REACT_APP_BUCKETNAME,
        dirName: 'Gifts',
        region: 'ap-south-1',
        accessKeyId: process.env.REACT_APP_S3_ACCESS_KEY,
        secretAccessKey: process.env.REACT_APP_S3_SECRET_KEY,
    };

    const filehandleChange = async (event) => {
        const files = Array.from(event.target.files);
        const newImages = [];
        const newImagePreviews = [];

        for (const file of files) {
            if (['image/png', 'image/jpeg', 'image/webp'].includes(file.type)) {
                try {
                    const timestamp = Date.now();
                    const newfile = new File([file], `${timestamp}.${file.type.split('/')[1]}`);

                    setLoading(true);
                    const res = await S3.uploadFile(newfile, config);

                    const endpoint = res.location.split('/').pop();
                    newImages.push(endpoint);
                    newImagePreviews.push(URL.createObjectURL(file));
                } catch (error) {
                    console.error('Error uploading image:', error);
                    setLoading(false);
                    Error('Error uploading image');
                }
            } else {
                alert(`${file.name} is neither PNG nor jpeg`);
            }
        }

        setImage([...image, ...newImages]);
        setImagePreviews([...imagePreviews, ...newImagePreviews]);
        setValue('GiftImages', [...image, ...newImages]);
        setLoading(false);
    };

    const removeImage = (index) => {
        const updatedImages = [...image];
        const updatedPreviews = [...imagePreviews];

        updatedImages.splice(index, 1);
        updatedPreviews.splice(index, 1);

        setImage(updatedImages);
        setImagePreviews(updatedPreviews);
        setValue('GiftImages', updatedImages);
    };

    const onSubmit = async (body) => {
        setLoading(true);
        try {
            body.ProductID = parseInt(body.ProductID, 10);
            body.VendorID = parseInt(body.VendorID, 10);
            body.GiftImages = image;
            const token = { Authorization: `token ${localStorage.getItem('token')}` };
            const response = await Services.updateGifts(gift.GiftID, "PUT", JSON.stringify(body), token);
            if (response.Status === 1) {
                setLoading(false);
                navigate('/gifts');
                Success(response.Message);
            } else {
                setLoading(false);
                Error(response.Message);
            }
        } catch (error) {
            console.error('Error updating gift:', error);
            setLoading(false);
            Error('Error updating gift');
        }
    };

    const fileuploadDesign = (
        <div className={TutorClasses["add-item"]} onClick={() => document.getElementById("image").click()}>
            <div className={TutorClasses['upload-image']}>
                {imagePreviews.map((preview, index) => (
                    <div key={index} className={TutorClasses['image-container']}>
                        <button type="button" className={TutorClasses['remove-button']} onClick={() => removeImage(index)}>
                            <IoIosClose className={TutorClasses['close-img-btn']} />
                        </button>
                        <img src={preview} alt="Selected" className={TutorClasses['uploaded-coupon-image']} />
                    </div>
                ))}
                {!imagePreviews.length && (
                    <div className={TutorClasses['add-item']} onClick={() => document.getElementById("image").click()}>
                        <BsCloudUpload className={TutorClasses['upload-icon']} />
                        <h5>Drag Your Files here</h5>
                        <p>(Only *.jpeg, *.png will be accepted)</p>
                    </div>
                )}
                <input type="file" id="image" onChange={filehandleChange} multiple style={{ display: 'none' }} />
            </div>
        </div>
    );

    return (
        <Layout Active={'Gifts'}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Update Gift</title>
            </Helmet>
            <div className={CommonClasses['Container']}>
                <div className={CommonClasses["control-btns"]}>
                    <button className={CommonClasses["button"]} onClick={() => navigate(-1)}>Back</button>
                </div>
                <div className={TutorClasses['wrapper']} data-aos="zoom-in-down">
                    <h2 className={TutorClasses['main-header']}>Update Gift</h2>
                    <form className={TutorClasses["form"]} onSubmit={handleSubmit(onSubmit)}>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="GiftName">Gift Name</label>
                            <input type='text' {...register('GiftName')} required />
                        </div>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="ProductID">ProductID</label>
                            <input type='number' step="0.01" {...register('ProductID')} required />
                        </div>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="VendorID">VendorID</label>
                            <input type='number' step="0.01" {...register('VendorID')} required />
                        </div>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="GiftStatus">Gift Status</label>
                            <select {...register('GiftStatus')} defaultValue={gift?.GiftStatus || ""} required>
                                <option value="">Select Gift Status</option>
                                <option value="1">Active</option>
                                <option value="2">InActive</option>
                            </select>
                        </div>
                        <div className={TutorClasses['file-upload-wrapper']}>
                            <header>
                                <h3>
                                    Upload Gift Images <span className="important">*</span>
                                </h3>
                            </header>
                            <div className={TutorClasses["upload-image"]} required>
                                {fileuploadDesign}
                            </div>
                        </div>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="Price">Price</label>
                            <input type='number' step="0.01" {...register('Price')} required />
                        </div>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="OfferPrice">Offer Price</label>
                            <input type='number' step="0.01" {...register('OfferPrice')} required />
                        </div>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="GiftDescription">Gift Description</label>
                            <textarea {...register('GiftDescription')} required />
                        </div>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="Material">Material</label>
                            <input type='text' {...register('Material')} required />
                        </div>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="Capacity">Capacity</label>
                            <input type='text' {...register('Capacity')} required />
                        </div>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="ProductDimension">Product Dimension</label>
                            <input type='text' {...register('ProductDimension')} required />
                        </div>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="TopDiameter">Top Diameter</label>
                            <input type='text' {...register('TopDiameter')} required />
                        </div>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="PackageInclude">Package Include</label>
                            <input type='text' {...register('PackageInclude')} required />
                        </div>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="QuantityAvailable">Quantity Available</label>
                            <input type='number' {...register('QuantityAvailable')} required />
                        </div>
                        <div className={TutorClasses['form-control']}>
                            <label htmlFor="CategoryIDs">Category</label>
                            <Controller
                                name="CategoryIDs"
                                control={control}
                                defaultValue={[]}
                                render={({ field: { onChange, value } }) => (
                                    <Autocomplete
                                        multiple
                                        options={categories}
                                        disableCloseOnSelect
                                        getOptionLabel={(option) => option.CategoryName}
                                        onChange={(event, newValue) => {
                                            const selectedOptions = newValue.map(option => option.CategoryID);
                                            onChange(selectedOptions);
                                        }}
                                        value={categories.filter(cat => value.includes(cat.CategoryID))}
                                        renderOption={(props, option, { selected }) => (
                                            <li {...props}>
                                                <Checkbox
                                                    icon={icon}
                                                    checkedIcon={checkedIcon}
                                                    style={{ marginRight: 8 }}
                                                    checked={selected}
                                                />
                                                {option.CategoryName}
                                            </li>
                                        )}
                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Categories"
                                                variant="outlined"
                                                sx={{ width: '500px' }} // Make TextField full width
                                            />
                                        )}
                                        sx={{ width: '100%' }} // Make Autocomplete full width
                                    />
                                )}
                            />
                        </div>

                        <button disabled={loading}>
                            {loading ? (
                                <ReactLoading color="green" type="spokes" height={30} width={30} />
                            ) : (
                                'Submit'
                            )}
                        </button>
                    </form>
                </div>
            </div>
        </Layout>
    );
};

export default EditGifts;
