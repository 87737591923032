import { Error_Dark } from "./Util/Toast";
const httpcall = (url, method, body, token) => {
    return fetch(`${process.env.REACT_APP_BASE_URL}/${url}`, {
        method: method,

        body: body,
        headers: {
            "Content-type": "application/json; charset=UTF-8",
            ...token,
        },
    })
        .then((response) => response.json())
        .then((res) => {
            if (res.Status === 0) {
                if (
                    res.Message === "Invalid Token" ||
                    res.Message === "Token expired"
                ) {
                    localStorage.clear();
                    sessionStorage.clear();
                    window.location.href = "/";
                    Error_Dark("Token Expired");
                }
            }
            return res;
        });
};

const Login = (method, body) => {
    return httpcall("adminuser/login", method, body);
};

const getUsers = (method, body, token) => {
    return httpcall("adminuser/list_Users", method, body, token);
};

const searchUsers = (method, body,token) => {
    // const body = { SearchString: searchString };
    return httpcall("adminuser/list_Users", method, body, token);
};

const getBanners = (method, body, token) => {
    return httpcall("adminuser/list_Banners", method, body, token);
};

const getPackages = (method, body, token) => {
    return httpcall("adminuser/list_packages", method, body, token);
};

const addPackages = (method, body, token) => {
    return httpcall("adminuser/add_package", method, body, token);
};

const updatePackage = (PackageID, method, body, token) => {
    return httpcall(`adminuser/update_package/${PackageID}`, method, body, token);
};

const listEvents = (method, body, token) => {
    return httpcall("adminuser/list_events", method, body, token);
};

const listOrders = (method, body, token) => {
    return httpcall("adminuser/list_orders", method, body, token);
};

const getOrderDetails = (OrderID, token) => {
    return httpcall(`adminuser/order_detail/${OrderID}`, "GET", null, token);
};

const getGifts = (method, body, token) => {
    return httpcall("adminuser/list_gifts", method, body, token);
};

const addGifts = (method, body, token) => {
    return httpcall("adminuser/add_gift", method, body, token);
};

const updateGifts = (GiftID, method, body, token) => {
    return httpcall(`adminuser/update_gift/${GiftID}`, method, body, token);
};


const getCoupons = (method, body, token) => {
    return httpcall("adminuser/list_coupons", method, body, token);
};

const addBanner = (method, body, token) => {
    return httpcall("adminuser/add_banner", method, body, token);
};

const updateBanner = (BannerID, method, body, token) => {
    return httpcall(`adminuser/update_banner/${BannerID}`, method, body, token);
};

const getCategories = (method, body, token) => {
    return httpcall("adminuser/list_categories", method, body, token);
};

const addCategory = (method, body, token) => {
    return httpcall("adminuser/add_category", method, body, token);
};

const updateCategory = (CategoryID, method, body, token) => {
    return httpcall(`adminuser/update_category/${CategoryID}`, method, body, token);
};

const getBannerDetails = (BannerID, token) => {
    return httpcall(`adminuser/banner_details/${BannerID}`, "GET", null, token);
};

const getEventDetails = (EventID, token) => {
    return httpcall(`adminuser/event_details/${EventID}`, "GET", null, token);
};

const getTemplates = (method, body, token) => {
    return httpcall("adminuser/list_templates", method, body, token);
};

const addTemplate = (method, body, token) => {
    return httpcall("adminuser/add_template", method, body, token);
};

const updateTemplate = (TemplateID, method, body, token) => {
    return httpcall(`adminuser/update_template/${TemplateID}`, method, body, token);
};

const deleteBanner = (BannerID, token) => {
    return httpcall(`adminuser/delete_banner/${BannerID}`, "DELETE", null, token);
};

const deleteCategory = (CategoryID, token) => {
    return httpcall(`adminuser/delete_category/${CategoryID}`, "DELETE", null, token);
};

const deletePackage = (PackageID, token) => {
    return httpcall(`adminuser/delete_package/${PackageID}`, "DELETE", null, token);
};

const addCouponCode = (method, body, token) => {
    return httpcall("adminuser/add_coupons", method, body, token);
};

const updateCouponCode = (CouponID, method, body, token) => {
    return httpcall(`adminuser/update_coupons/${CouponID}`, method, body, token);
};

const updateOrder = (OrderID, method, body, token) => {
    return httpcall(`adminuser/update_order/${OrderID}`, method, body, token);
};


export const Services = {
    Login,
    getUsers,
    searchUsers,
    getBanners,
    getPackages,
    listEvents,
    listOrders,
    getOrderDetails,
    getGifts,
    getCoupons,
    addBanner,
    updateBanner,
    getCategories,
    addCategory,
    updateCategory,
    addPackages,
    updatePackage,
    addGifts,
    updateGifts,
    getBannerDetails,
    getEventDetails,
    getTemplates,
    addTemplate,
    updateTemplate,
    deleteBanner,
    deleteCategory,
    deletePackage,
    addCouponCode,
    updateCouponCode,
    updateOrder,
};
