import React, { useState, useEffect } from "react";
import Moment from "react-moment";
import { FaRegEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Services } from "Services";
import { warningSwal } from "Util/Toast";
import SkeletonLoad from "Components/TableLoad/load";
import CommonClasses from "Styles/Common.module.css";
import { Helmet } from "react-helmet";
import Layout from "Components/Layout";
import { BiPlus } from "react-icons/bi";
import { TablePagination } from "@mui/material";

function Templates() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const fetchTemplates = () => {
        setLoading(true);
        Services.getTemplates("GET", null, token)
            .then((response) => {
                setLoading(false);
                if (response.Status === 1) {
                    setData(response.Templates);
                } else if (response.Status === 0) {
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert("Something went wrong. Please try again.");
                console.error(err);
            });
    };

    useEffect(() => {
        fetchTemplates();
    }, []);

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    return (
        <Layout Active={"Templates"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Templates</title>
            </Helmet>
            <div className={CommonClasses["Container"]}>
                <div className={CommonClasses["control-btns"]}>
                    <button
                        className={CommonClasses["button"]}
                        onClick={() => {
                            navigate("/addtemplate");
                        }}
                    >
                        <BiPlus size={20} /> Create New Template
                    </button>
                </div>
                <div className={CommonClasses["wrapper"]}>
                    {loading ? (
                        <SkeletonLoad
                            TableHead={[
                                "TemplateImage",
                                "TemplateName",
                                "CreatedAt",
                                "UpdatedAt",
                                "TemplateStatus",
                                "Actions",
                            ]}
                        />
                    ) : data.length > 0 ? (
                        <div className={CommonClasses["Table"]}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>TemplateImage</th>
                                        <th>TemplateName</th>
                                        <th>CreatedAt</th>
                                        <th>UpdatedAt</th>
                                        <th>TemplateStatus</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data
                                        .slice(
                                            page * rowsPerPage,
                                            page * rowsPerPage + rowsPerPage
                                        )
                                        .map((item) => (
                                            <tr
                                                key={item.TemplateID}
                                                id={item.TemplateID}
                                                name={item.TemplateName}
                                                // onClick={() =>
                                                //     navigate(
                                                //         `/bannerdetails/${item.TemplateID}`
                                                //     )
                                                // }
                                                style={{ cursor: "pointer" }}
                                            >
                                                <td>
                                                    <img
                                                        style={{
                                                            width: "10rem",
                                                            height: "5rem",
                                                            objectFit: "fill",
                                                            borderRadius:
                                                                "5px",
                                                        }}
                                                        src={item.Template}
                                                        alt="Template-img"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            window.open(
                                                                item.Template,
                                                                "_blank"
                                                            );
                                                        }}
                                                    />
                                                </td>
                                                <td>{item.TemplateName}</td>
                                                <td>
                                                    <Moment format="DD-MM-YYYY">
                                                        {item.CreatedAt}
                                                    </Moment>
                                                </td>
                                                <td>
                                                    <Moment format="DD-MM-YYYY">
                                                        {item.UpdateAt}
                                                    </Moment>
                                                </td>
                                                <td>
                                                    {item.TemplateStatus ===
                                                    1 ? (
                                                        <div
                                                            className={
                                                                CommonClasses[
                                                                    "Status-active"
                                                                ]
                                                            }
                                                        >
                                                            <span>Active</span>
                                                        </div>
                                                    ) : (
                                                        <div
                                                            className={
                                                                CommonClasses[
                                                                    "Status-inactive"
                                                                ]
                                                            }
                                                        >
                                                            <span>
                                                                Inactive
                                                            </span>
                                                        </div>
                                                    )}
                                                </td>
                                                <td>
                                                    <div
                                                        className={
                                                            CommonClasses[
                                                                "Action-btns"
                                                            ]
                                                        }
                                                    >
                                                        <button
                                                            onClick={(e) => {
                                                                e.stopPropagation();
                                                                navigate(
                                                                    `/updatetemplate/${item.TemplateID}`,
                                                                    {
                                                                        state: {
                                                                            template: item,
                                                                        },
                                                                    }
                                                                );
                                                            }}
                                                        >
                                                            <FaRegEdit />
                                                        </button>
                                                    </div>
                                                </td>
                                            </tr>
                                        ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div>
                            <p>No Templates Found</p>
                        </div>
                    )}
                    <TablePagination
                        rowsPerPageOptions={[5, 10, 25]}
                        component="div"
                        count={data.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                        className={CommonClasses["pagination"]}
                    />
                </div>
            </div>
        </Layout>
    );
}

export default Templates;
