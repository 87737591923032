import React, { useState, useEffect, useCallback } from "react";
import Moment from "react-moment";
import { FaRegEdit } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { Services } from "Services";
import { SuccessSwal, warningSwal } from "Util/Toast";
import SkeletonLoad from "Components/TableLoad/load";
import CommonClasses from "Styles/Common.module.css";
import { Helmet } from "react-helmet";
import Layout from "Components/Layout";
import { BiPlus } from "react-icons/bi";
import debounce from "lodash/debounce";
import UserClasses from '../users/Users.module.css'
import { AiFillDelete } from "react-icons/ai";
import { IoIosWarning } from "react-icons/io";
import { Button } from "@mui/material";

function Packages() {
    const navigate = useNavigate();
    const [loading, setLoading] = useState(true);
    const [data, setData] = useState([]);
    const [searchString, setSearchString] = useState("");
    const [showModal, setShowModal] = useState(false);
    const [selectedPackage, setSelectedPackage] = useState(null);
    const token = { Authorization: `token ${localStorage.getItem("token")}` };

    const fetchPackages = () => {
        setLoading(true);
        Services.getPackages("GET", null, token)
            .then((response) => {
                setLoading(false);
                if (response.Status === 1) {
                    setData(response.Packages);
                } else if (response.Status === 0) {
                    warningSwal("Warning", response.Message);
                }
            })
            .catch((err) => {
                setLoading(false);
                alert("Something went wrong. Please try again.");
                console.error(err);
            });
    };

    useEffect(() => {
        fetchPackages();
    }, []);

    const handleDeleteClick = (packages) => {
        setSelectedPackage(packages);
        setShowModal(true);
    };

    const confirmDelete = () => {
        if (selectedPackage) {
            Services.deletePackage(selectedPackage.PackageID, token)
                .then((response) => {
                    if (response.Status === 1) {
                        SuccessSwal("Success", "Package deleted successfully!");
                        fetchPackages(); // Refresh the banners list
                    } else {
                        warningSwal("Warning", response.Message);
                    }
                })
                .catch((err) => {
                    alert("Something went wrong. Please try again.");
                    console.error(err);
                })
                .finally(() => {
                    setShowModal(false);
                    setSelectedPackage(null);
                });
        }
    };

    return (
        <Layout Active={"Packages"}>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Users</title>
            </Helmet>
            <div className={CommonClasses["Container"]}>
                <div className={CommonClasses["control-btns"]}>
                    <button
                        className={CommonClasses["button"]}
                        onClick={() => {
                            navigate("/addpackage");
                        }}>
                        <BiPlus size={20} /> Create New Package
                    </button>
                </div>
                <div className={CommonClasses["wrapper"]}>
                    {loading ? (
                        <SkeletonLoad
                            TableHead={["PackageImage", "PackageName", "CreatedAt", "UpdatedAt", "PackageStatus", "Actions"]}
                        />
                    ) : data.length > 0 ? (
                        <div className={CommonClasses["Table"]}>
                            <table>
                                <thead>
                                    <tr>
                                        <th>PackageImage</th>
                                        <th>PackageName</th>
                                        <th>CreatedAt</th>
                                        <th>UpdatedAt</th>
                                        <th>PackageStatus</th>
                                        <th>Actions</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {data.map((item) => (
                                        <tr
                                            key={item.PackageID}
                                            id={item.PackageID}
                                            name={item.PackageName}>
                                            <td>
                                                <img
                                                    style={{ width: "10rem", height: "5rem", objectFit: "fill", borderRadius: '5px' }}
                                                    src={item.PackageImage}
                                                    alt="user-img"
                                                    onClick={() => {
                                                        window.open(item.PackageImage, "_blank");
                                                    }}
                                                />
                                            </td>
                                            <td>{item.PackageName}</td>
                                            <td>
                                                <Moment format="DD-MM-YYYY">
                                                    {item.CreatedAt}
                                                </Moment>
                                            </td>
                                            <td>
                                                <Moment format="DD-MM-YYYY">
                                                    {item.UpdateAt}
                                                </Moment>
                                            </td>
                                            <td>
                                                {item.PackageStatus === 1 ? (
                                                    <div className={CommonClasses["Status-active"]}>
                                                        <span>Active</span>
                                                    </div>
                                                ) : (
                                                    <div className={CommonClasses["Status-inactive"]}>
                                                        <span>Inactive</span>
                                                    </div>
                                                )}
                                            </td>
                                            <td>
                                                <div className={CommonClasses["Action-btns"]}>
                                                    <button
                                                        onClick={() => navigate(`/updatepackage/${item.PackageID}`, { state: { newpackage: item } })}>
                                                        <FaRegEdit />
                                                    </button>
                                                    <button
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleDeleteClick(item);
                                                        }}>
                                                        <AiFillDelete className={CommonClasses['del-main-btn']}/>
                                                    </button>
                                                </div>
                                            </td>
                                        </tr>
                                    ))}
                                </tbody>
                            </table>
                        </div>
                    ) : (
                        <div>
                            <p>No Users Found</p>
                        </div>
                    )}
                </div>
            </div>
            {showModal && (
                <div className={CommonClasses["modal"]}>
                    <div className={CommonClasses["modal-content"]} data-aos="zoom-in">
                        <h1><IoIosWarning className={CommonClasses['del-warning']}/></h1>
                        <h3>Confirm Delete</h3>
                        <p>Are you sure you want to delete this Package?</p>
                        <div className={CommonClasses["modal-actions"]}>
                            <Button variant="outlined" onClick={confirmDelete}>OK</Button>
                            <Button variant="outlined" color="secondary" onClick={() => setShowModal(false)}>Cancel</Button>
                        </div>
                    </div>
                </div>
            )}
        </Layout>
    );
}

export default Packages;
